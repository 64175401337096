.App {
  height: 100dvh;
  width: 100dvw;
  background-image: url("../src/imgs/background.svg");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  justify-content: center;
}

.footer {
  background-color: #005e60;
  width: 100%;
  height: 8%;
  position: absolute;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    color: white;
    font-size: 1.6dvh;
    margin: 0px;
  }
}

.registration {
  background-color: white;
  border-radius: 2dvh;
  min-height: 70%;
  max-height: 87%;
  width: 95%;
  position: absolute;
  top: 5%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  box-shadow: 0px 0px 1dvh 0.5dvh rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  padding: 3% 0%;
  animation: slide-down 1s ease-in-out;
  img {
    height: 20%;
    width: 40%;
    margin-top: 5%;
  }
  h2 {
    margin: 5% 0%;
    font-size: 3dvh;
  }
  #form {
    width: 90%;
    .form-group {
      .input-title {
        margin: 0% 0% 1% 0%;
        font-size: 2dvh;
      }
      input {
        box-sizing: border-box;
        font-size: 2.6dvh;
        width: 100%;
        height: 7dvh;
        border-radius: 1dvh;
        padding: 0% 3%;
        border: none;
        background-color: #0000001a;
      }
    }
    #name, #company {
      margin-bottom: 5%;
    }

    .form-error {
      color: #e83f36;
      font-size: 2dvh;
      margin: 0% 0% 0.5% 0%;
    }
  }
  button {
    width: 90%;
    background-color: #c8ff08;
    color: #005e60;
    font-size: 2dvh;
    font-weight: bold;
    border: none;
    height: 7dvh;
    border-radius: 1dvh;
    margin-top: 5%;
  }
  #login-registration-switch {
    margin-top: 3%;
    display: flex;
    flex-direction: row !important;
    width: 100%;
    justify-content: center;
    font-weight: 600;
    font-size: 2dvh;
    text-align: center;
    div {
      display: inline-block;
    }
    #loginRegister {
      color: #005e60;
      font-weight: 600;
      margin: 0px 0px 0px 1%;
    }
  }
}

.header {
  width: 100%;
  height: 10%;
  background-color: #005e60;
  padding: 0% 5%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 0px 10px 0.7dvh rgba(0, 0, 0, 0.2);
  #header-left {
    display: flex;
    align-items: center;
    color: white;
    width: 80%;
    overflow-x: hidden;
    font-size: 2.5dvh;
    img {
      margin-right: 5%;
      height: 60%;
    }
  }
  #header-right {
    color: white;
    font-size: 2.5dvh;
    align-content: center;
  }
}

.home {
  height: 92%;
  width: 100%;
  position: relative;
  #content {
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    position: relative;
    #upper-content {
      background-image: url('./imgs/upper-content-background.svg');
      background-size:100%;
      background-repeat: no-repeat;
      height: 30%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: end;
      animation: appear 1.5s ease-in-out;
      #instructions {
        box-sizing: border-box;
        padding: 0% 5%;
        background-color: rgba(255, 255, 255, 0.85);
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 90%;
        border-radius: 2dvh 2dvh 0px 0px;
        #instruction-title {
          font-size: 3dvh;
          margin: 6% 0% 3% 0%;
        }
        #instruction-text {
          text-align: center;
          margin: 0px;
          font-size: 2dvh;
        }
        #instruction-counter {
          margin-bottom: 0px;
          margin-top: 5%;
          color: #005e60;
          font-weight: 600;
          font-size: 2dvh;
        }
      }
    }
    #lower-content {
      height: 70%;
      width: 100%;
      background-color: #f6f6f6;
      border-top: 1px solid #005e60;
      overflow: auto;
      padding: 8% 5% 0% 5%;
      box-sizing: border-box;
      animation: slide-up 1s ease-in-out;
    }
  }
  #redeem-button {
    position: absolute;
    z-index: 2;
    background-color: #c8ff08;
    width: 50%;
    font-weight: 600;
    font-size: 2dvh;
    border: none;
    border-radius: 1dvh;
    height: 5dvh;
    top: 27%;
    left: 50%;
    transform: translateX(-50%);
    animation: appear 2.5s ease-in-out;
  }
  .disable-redeem {
    color: #98df00;
  }
  .enable-redeem {
    color: #005e60;
  }
  .redeemed {
    background-color: #dfdfdf !important;
    color: #b8b8b8;
  }
}

#stamp {
  position: relative;
  width: 100%;
  background-color: white;
  border-radius: 1dvh;
  height: 20dvh;
  border: 1px solid #d6d6d6;
  margin-bottom: 5%;
  box-sizing: border-box;
  #stamp-top {
    background-color: #f6f6f6;
    border-radius: 1dvh 1dvh 0px 0px;
    height: 30%;
    padding: 0% 5%;
    display: flex;
    align-items: center;
    color: #005e60;
    font-weight: 600;
    border-bottom: 1px solid #d6d6d6;
    font-size: 2dvh;
    #completion-status {
      margin: 0px;
    }
    .completed-text-color {
      color: #005e60;
    }
    .uncompleted-text-color {
      color: #adadad;
    }
  }
  #stamp-bottom {
    padding: 2% 5% 5% 5%;
    box-sizing: border-box;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h4 {
      margin: 0px;
      font-size: 2dvh;
    }
    p {
      margin: 0px;
      font-size: 2dvh;
    }
  }
  #stamp-btn {
    position: absolute;
    border-radius: 50%;
    border: 1px solid #d6d6d6;
    height: 13dvh;
    width: 13dvh;
    right: 5%;
    top: 18%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    #stamp-btn-inner {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      position: relative;
      height: 65%;
      aspect-ratio: 1/1;
      text-align: center;
      align-content: center;
      font-weight: 600;
      font-size: 1.8dvh;
      img {
        height: 100%;
      }
      #visited-text {
        color: white;
      }
      #scanQr-text {
        color: #005e60;
      }
      p {
        position: absolute;
        margin: 0px;
      }
    }
  }
  .completed-btn-color {
    background-color: #005e60;
  }
  .uncompleted-btn-color {
    background-color: #f6f6f6;
  }

  .uncompleted-text-color {
    color: #adadad;
  }
}

#qr {
  position: fixed;
  z-index: 5;
  height: 100dvh;
  width: 100dvw;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  #qr-box {
    background-color: #005e60;
    width: 85%;
    padding: 1% 3% 5%;
    border-radius: 2dvh;
    display: flex;
    flex-direction: column;
    .close-btn {
      align-self: flex-end;
      margin-right: 1%;
      margin-top: 5%;
      height: 2.5dvh;
    }
    #qr-container {
      align-self: center;
      margin-top: 5%;
      box-sizing: border-box;
      max-height: 70%;
      width: 90%;
      #qr-scanner {
        height: 100%;
        width: 100%;
        box-sizing: border-box;
      }
      #qr-code {
        height: auto;
        width: 100%;
        box-sizing: border-box;
      }
    }
    .qr-code-border {
      border-radius: 2dvh;
      border: 1.5dvh solid white; 
      background-color: white;
    }
    #user-id {
      align-self: center;
      color: white;
      font-size: 3dvh;
      margin: 6% 0% 0% 0%;
    }
  }
}

#quiz {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background-color: #f6f6f6;
  overflow: auto;
  padding: 4% 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: quiz-slide-up 1s ease-in-out;
  #back-button-container {
    align-content: center;
    width: 100%;
    #back-button {
      border: none;
      background-color: transparent;
      display: flex;
      align-items: center;
      color: #005e60;
      font-weight: 600;
      font-size: 2dvh;
      img {
        margin-right: 15%;
        height: 2dvh;
      }
    }
  }
  #question-title {
    margin: 3% 0%;
    font-size: 3dvh;
  }
  #question {
    width: 90%;
    text-align: center;
    margin: 0px;
    font-size: 2dvh;
  }
  #answers {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 10%;
    margin-bottom: 8%;
    height: 40%;
    .answer-row {
      display: flex;
      justify-content: space-between;
      height: 48%;
      width: 100%;
      .answer-box {
        text-align: center;
        align-content: center;
        background-color: white;
        border: 1px solid #d6d6d6;
        border-radius: 1dvh;
        height: 100%;
        width: 49%;
        padding: 5%;
        box-sizing: border-box;
        font-size: 2dvh;
      }
      .selected-answer {
        background-color: #005e60;
        color: white;
      }
      .wrong-answer {
        background-color: #ec642b;
        color: white;
      }
    }
  }
  #submit-btn {
    width: 100%;
    border: none;
    background-color: #c8ff08;
    border-radius: 1dvh;
    color: #005e60;
    padding: 5%;
    font-size: 2dvh;
    font-weight: 600;
  }

  #wrong-msg {
    color: #ec642b;
    margin-top: 3%;
    font-size: 2dvh;
    margin-bottom: 0px;
  }
}

#win {
  position: absolute;
  top: 10%;
  background-color: white;
  box-sizing: border-box;
  width: 95%;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 2dvh;
  padding: 8% 5%;
  animation: slide-down 1s ease-in-out;
  img {
    height: 15dvh;
  }
  h3 { 
    margin-bottom: 0px;
    font-size: 3dvh;
  }
  p {
    text-align: center;
    width: 90%;
    font-size: 2dvh;
    margin-bottom: 10%;
  }
  button {
    width: 100%;
    background-color: #C8FF08;
    color:#005E60;
    font-weight: 600;
    border: none;
    border-radius: 1dvh;
    height: 7dvh;
    font-size: 2dvh;
  }
}

@media (orientation: landscape) {
  .registration {
    top: 3%;
    padding-bottom: 1.5%;
    img {
      width: 20%;
      height: 20%;
      margin-top: 1%;
    }
    h2 {
      font-size: 3dvw;
      margin: 2% 0% 1%;
    }
    #form {
      .form-group {
        p {
          font-size: 2dvw !important;
        }
        input {
          font-size: 3dvh;
        }
      }
      #name {
        margin-bottom: 2%;
      }
    }
    #login-registration-switch {
      font-size: 2dvw;
    }
    button {
      margin-top: 2%;
      padding: 1.5% 0%;
      text-align: center;
      align-content: center;
      font-size: 2dvw;
      height: auto;
    }
    #login-registration-switch {
      margin-top: 2%;
    }
  }
  .header {
    #header-left {
      font-size: 2.5dvw;
    }
    #header-right {
      font-size: 2.5dvw;
    }
  }
  #stamp {
    height: 20dvw;
    #stamp-top {
      font-size: 2dvw;
    }
    #stamp-bottom {
      h4 {
        font-size: 2dvw;
      }
      p {
        font-size: 2dvw;
      }
    }
    #stamp-btn {
      height: 13dvw;
      width: 13dvw;
      p {
        font-size: 1.7dvw;
      }
    }
  }
  .home {
    #upper-content {
      height: 35% !important;
      #instructions {
        #instruction-title {
          font-size: 3dvw !important;
          margin: 3% 0% 1% 0% !important;
        }
        #instruction-text {
          font-size: 2dvw !important;
        }
        #instruction-counter {
          font-size: 2dvw !important;
          margin-top: 2% !important;
        }
      }
    }
    #lower-content {
      padding: 5% 5% 0% 5% !important;
      height: 65% !important;
    }
    #redeem-button {
      height: 5dvw;
      font-size: 2dvw;
      top: 31%;
    }
  }
  #qr {
    #qr-box {
      height: 80%;
      .close-btn{
        height: 2.5dvw;
      }
      #qr-container {
        height: 70%;
        width: auto;
        #qr-code {
          height: 100%;
          width: auto;
        }
      }
      #user-id {
        font-size: 3dvw;
      }
    }
  }
  #quiz {
    padding: 2% 5%;
    #back-button-container {
      #back-button {
        font-size: 2dvw;
        img {
          height: 2dvw;
        }
      }
    }
    #question-title {
      font-size: 3dvw;
      margin: 2% 0%;
    }
    #question {
      font-size: 2dvw;
    }
    #answers {
      height: 70%;
      margin-top: 8%;
      margin-bottom: 4%;
      .answer-row {
        .answer-box {
          font-size: 2dvw;
        }
      }
    }
    #submit-btn {
      font-size: 2dvw;
      padding: 2%;
    }
    #wrong-msg {
      font-size: 2dvw;
      margin-top: 1%;
    }
  }
  #win {
    margin-top: 5%;
    padding: 4% 5%;
    img {
      height: 15dvw;
    }
    h3 {
      font-size: 3dvw;
    }
    p {
      font-size: 2dvw;
      margin-bottom: 5%;
    }
    button {
      height: 7dvw;
      font-size: 2dvw;
    }
  }
  .footer {
    p {
      font-size: 1.5dvw;
    }
  }
}

@keyframes slide-down {
  0% {
    top: -100%;
    opacity: 0;
  }
  100% {
    top: 5%;
    opacity: 1;
  }
}

@keyframes slide-up {
  0% {
    height: 0%;
    /* opacity: 0; */
  }
  100% {
    height: 70%;
    /* opacity: 1; */
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes quiz-slide-up {
  0% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}